import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import DefaultLayout from "./layouts/Default";
import UserLayout from "./layouts/Users";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error.vue";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import VuejsPaginate from "vuejs-paginate";
import store from "./store/index";
import VueConfirmDialog from "vue-confirm-dialog";
import Spinner from "vue-simple-spinner";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
// import AdminLandingLayout from "./layouts/AdminLanding.vue";
// import { CONFIG } from "@/config/config";
// import Logo from "./assets/main-logo.svg";
import VueToastify from "vue-toastify";
import Logo from "./assets/jiangbei.svg";
//导入element
//按需引入
import { Descriptions,Badge,Tag,DescriptionsItem,Pagination,Button,Dialog,Input,Form,FormItem,Tooltip,Upload } from 'element-ui';
// import vueConfig from "../vue.config";
// import 'element-ui/lib/theme-chalk/index.css';

Vue.component('el-descriptions', Descriptions);
Vue.component('el-badge', Badge);
Vue.component('el-tag', Tag);
Vue.component('el-descriptions-item', DescriptionsItem);
Vue.component('el-pagination',Pagination)
Vue.component('el-button',Button)
Vue.component('el-dialog',Dialog)
Vue.component('el-input',Input)
Vue.component('el-form',Form)
Vue.component('el-form-item',FormItem)
Vue.component('el-tooltip',Tooltip)
Vue.component('el-upload',Upload)
// //markdown渲染
// import VMdPreview from '@kangc/v-md-editor/lib/preview';
// import '@kangc/v-md-editor/lib/style/preview.css';
// // 引入你所使用的主题 此处以 github 主题为例
// import '@kangc/v-md-editor/lib/style/base-editor.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github';
// import '@kangc/v-md-editor/lib/theme/style/github.css';




// // 快速复制代码
// import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
// import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';



// // highlightjs
// import hljs from 'highlight.js';
// // 显示代码行数
// import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';

// VMdPreview.use(createLineNumbertPlugin());
// VMdPreview.use(createCopyCodePlugin());
// VMdPreview.use(githubTheme, {
//   Hljs: hljs,
// });
// Vue.use(VMdPreview);


//通知插件
Vue.use(VueToastify, {
  position: "top-center",
  canTimeout: true,
  errorDuration: 3000,
  successDuration: 3000,
  warningInfoDuration: 3000,
  theme: "light"
});
Vue.config.productionTip = false;
Vue.use(VueConfirmDialog);
Vue.component("default-layout", DefaultLayout);
Vue.component("user-layout", UserLayout);
Vue.component("auth-layout", AuthLayout);
Vue.component("error-layout", ErrorLayout);
// Vue.component("admin-landing-layout", AdminLandingLayout);
Vue.component("v-select", vSelect);
Vue.component("paginate", VuejsPaginate);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.component("loader", Spinner);
Vue.mixin({
  methods: {
    apiErrHandler: function(error) {
      console.log(error);
    },
    getImage(imagename) {
      return require(`../src/assets/${imagename}.svg`);
    },
    getLogoUrl() {
      let logoName = store.state.competitionInfo.logo;
      if (!logoName) {
        return Logo;
      }
      return ``;
    }
  }
});

async function init() {
  // const response = await configureService.getConfigs();
  // console.log(response.data.data.list[0])
  // let configs = response.data.data.list[0];
  // let competitionInfo = {
  //   name: configs.name,
  //   about: configs.about,
  //   prizes: "You get to learn!",
  //   timezone: "Asia/Calcutta: UTC +05:30",
  //   startingTime: "00:00:00 UTC: +05:30, 15 May 2023, Monday",
  //   endingTime: configs.ending_time,
  //   logo: configs.logo_url
  // };
  // store.commit("updateCompInfo", competitionInfo);
}

init().then(() => {
  new Vue({
    router,
    store,
    components: {
      ChartJsPluginDataLabels
    },
    render: h => h(App)
  }).$mount("#app");
});
